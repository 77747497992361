import React, { Component } from "react"
import Layout from "../../components/layout"
import HeaderBanner from "../../components/common/headerBanner/headerBanner"
import CTABanner from "../../components/common/ctaBanner/ctaBanner"
import * as styles from "../faq.module.scss"
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb"
import FAQCategoriesContainer from "../../components/sections/faq/faqCategoriesContainer/faqCategoriesContainer"
import FAQListContainer from "../../components/sections/faq/faqListContainer/faqListContainer"
import { TM } from "../../components/common/typography/trademark"

const generalQuestions = [
  {
    label: (
      <>
        What Is IronCAP X<TM />?
      </>
    ),
    id: "whatIs",
    widget: "text",
    option: (
      <>
        IronCAP X<TM /> is the world's first Quantum-safe end-to-end email
        encryption system utilizing IronCAP Crypto (ICC) as its cryptographic
        foundation with post-quantum cryptographic algorithms that are approved
        by NIST (National Institute of Standards and Technology in the US).
      </>
    ),
  },
  {
    label: "How do I sign up for a FREE account?",
    id: "freeAccount",
    widget: "text",
    option: (
      <>
        IronCAP X<TM /> is free for personal usage. To sign up for a FREE
        account, simply visit www.ironcapx.com and click "FREE Personal Usage".
        Enter your name and email address. Then click "Send Installation Email".
        An email will be sent to you with a link to install IronCAP X<TM /> onto
        your computer.
      </>
    ),
  },
  {
    label: (
      <>
        What is the system requirement to use IronCAP X<TM />?
      </>
    ),
    id: "systemRequirement",
    widget: "text",
    option: (
      <>
        IronCAP X<TM /> requires Windows 7 or above with Outlook 2010+ as the
        email client. Note: Web-based clients such as Gmail, Yahoo, and Hotmail
        will be supported in a future version.
      </>
    ),
  },
  {
    label: (
      <>
        Can IronCAP X<TM /> work with Outlook?
      </>
    ),
    id: "outlook",
    widget: "text",
    option: (
      <>
        Yes, IronCAP X<TM /> works with Outlook 2010 or above.
      </>
    ),
  },
  {
    label: (
      <>
        Can IronCAP X<TM /> work with other web-based email (e.g. Gmail)?
      </>
    ),
    id: "webEmail",
    widget: "text",
    option: (
      <>
        Not in the current version. Future version of IronCAP X<TM /> will work
        with web-based email clients such as Gmail, Yahoo, and Hotmail via
        installation of a browser plug-in. Compatible browsers are Chrome and
        Firefox.
      </>
    ),
  },
  {
    label: (
      <>
        Does IronCAP X<TM /> support mobile email clients?
      </>
    ),
    id: "mobileEmail",
    widget: "text",
    option: (
      <>
        Current version of IronCAP X<TM /> supports Windows-based Outlook.
        Support of webmail clients is coming soon.
      </>
    ),
  },
  {
    label: "Can I manage more than 1 email address on one computer?",
    id: "multipleEmail",
    widget: "text",
    option: (
      <>
        Current version of IronCAP X<TM /> only supports 1 email address per
        license. Future version will allow up to 3 email addresses on the same
        computer.
      </>
    ),
  },
]

const freeVsBusiness = [
  {
    label: "Will I be charged at all for the FREE Personal Usage account?",
    id: "charge",
    widget: "text",
    option: (
      <>
        IronCAP X<TM /> is free for personal usage. No credit card is needed to
        sign up for a FREE Personal Usage account.
      </>
    ),
  },
  {
    label: "Is there any feature limitation on the FREE account?",
    id: "limitation",
    widget: "text",
    option:
      "No. There is no feature limitation on the FREE account. The only limit is one user per FREE account for personal usage.",
  },
  {
    label: "Can I use the FREE account as a trial for a Business account?",
    id: "trial",
    widget: "text",
    option:
      "Yes. The Terms of Service allows businesses to use a FREE account as trial for 30 days (even if it is for commercial usage). You are legally required to convert to a Business account if you continue to use it beyond the 30 days.",
  },
  {
    label:
      "Can I use the FREE account for my business with only one user after the 30-day trial?",
    id: "oneUser",
    widget: "text",
    option:
      "Yes. You can continue to use the FREE account to read encrypted messages even though you are a business entity. However, you need to convert it to a Business account if you wish to send encrypted emails to your clients (i.e. for commercial usage). If you do not convert the account, all features still work but you may have legally breached the Terms of Use. We reserve the right to terminate the service at any time without giving prior notice.",
  },
  {
    label: (
      <>
        Is the IronCAP
        <TM /> Key compatible with the FREE account?
      </>
    ),
    id: "compatible",
    widget: "text",
    option: (
      <>
        Yes. IronCAP
        <TM /> Key is fully compatible with the FREE account. You will need to
        make a one-time non-refundable purchase of the IronCAP
        <TM /> Key.
      </>
    ),
  },
  {
    label:
      "If I have a Business account, can my clients use a FREE account to read my encrypted emails?",
    id: "clients",
    widget: "text",
    option:
      "Yes. Your clients can use a FREE account to read your encrypted messages. If they are a business entity, they will need to convert to a Business account only when they want to send encrypted emails to their clients (i.e. for commercial usage).",
  },
  {
    label: "What happens when a business account expires?",
    id: "expire",
    widget: "text",
    option: (
      <>
        You can still use the expired IronCAP X<TM /> Business license to read
        your encrypted messages. However, the ability to encrypt emails/files
        and to digitally sign your emails/files will be disabled until you have
        resubscribed.
      </>
    ),
  },
]

const security = [
  {
    label: (
      <>
        How secure is the IronCAP X<TM /> private key?
      </>
    ),
    id: "security",
    widget: "text",
    option: (
      <>
        The IronCAP X<TM /> private key is quantum-safe. This means it is not
        only safe against attacks from classical computers but also against
        attacks from future quantum computers. Its underlying cryptographic
        technology is IronCAP Crypto (ICC) which contains post-quantum
        cryptographic algorithms that are approved by NIST (National Institute
        of Standards and Technology in the US).
      </>
    ),
  },
  {
    label: "Where does my private key reside?",
    id: "privateKey",
    widget: "text",
    option: (
      <>
        Your IronCAP X<TM /> private key is stored locally in the computer where
        you have installed IronCAP X<TM />.
      </>
    ),
  },
  {
    label: (
      <>
        Does your IronCAP X<TM /> server store a copy of my private key?
      </>
    ),
    id: "keyCopy",
    widget: "text",
    option: (
      <>
        No copy of your private key is stored in our IronCAP X<TM /> server.
      </>
    ),
  },
  {
    label: "Is my private key encrypted?",
    id: "encrypted",
    widget: "text",
    option: (
      <>
        Yes, your IronCAP X<TM /> private key is always encrypted using your
        user password.
      </>
    ),
  },
  {
    label: "Where is the user password stored?",
    id: "passwordStorage",
    widget: "text",
    option: (
      <>
        Your IronCAP X<TM /> user password is not stored anywhere, neither on
        our IronCAP X<TM /> server nor your own computer/device. It is being
        used to encrypt your private key. The only way to decrypt your private
        key is to provide the correct password. In other words, it is impossible
        to retrieve your user password if you have forgotten it. We can only
        facilitate a reset of the password.
      </>
    ),
  },
  {
    label: "What if I forget the user password?",
    id: "forget",
    widget: "text",
    option: (
      <>
        IronCAP X<TM /> allows the Administrator of a Business account to reset
        the password of every user under the account. The password reset feature
        is not available to the individual user. To access the password reset
        feature, the account Administrator needs to provide the Administrator
        password to authenticate. There is a disaster recovery option defined by
        the Administrator of the account (5 secret questions and answers) to
        reset the Administrator password in case it is forgotten. The
        Administrator must know the disaster recovery secret questions and
        answers.
      </>
    ),
  },
  {
    label: "What does quantum-safe cryptography mean?",
    id: "quantumSafeDefinition",
    widget: "text",
    option:
      "Quantum Computer is a new breed of computer using a completely different computational concept than the simple silicon-based 0/1 as in the world of classical computers. Quantum Computer is based on quantum mechanics that allows simultaneous existence of 0/1 and therefore operating like millions of classical computers working in parallel. Its excessive computing power is making possible a lot of new computational tasks that are otherwise not possible by classical computers. However, its excessive power also allows hackers to crack virtually all modern encryption technologies. Quantum-safe is the fundamental bedrock in post-quantum cyber security. A quantum-safe cryptography is a cryptographic system that is safe against attacks from Quantum Computers.",
  },
  {
    label: "What if my computer is stolen?",
    id: "computerStolen",
    widget: "text",
    option:
      "All the encrypted emails stay encrypted and a password is required to decrypt the private key in order to read the encrypted emails.",
  },
  {
    label: "Can the password be guessed eventually?",
    id: "guessed",
    widget: "text",
    option: (
      <>
        Yes, especially if you use the same password for different applications.
        The best bet for ultra security will be to purchase the optional IronCAP
        <TM /> Key device. IronCAP
        <TM /> Key is a USB device that offloads the private key and
        cryptographic functions inside the device. If the IronCAP
        <TM /> Key has been removed when the computer is stolen, there is zero
        chance that the encrypted emails can be compromised because the private
        key is not even on the computer.
      </>
    ),
  },
  {
    label: (
      <>
        What if the IronCAP
        <TM /> Key itself is stolen?
      </>
    ),
    id: "keyStolen",
    widget: "text",
    option: (
      <>
        Similar to a credit card, the private key inside the IronCAP
        <TM /> Key is protected by a PIN. If the PIN is entered incorrectly 5
        times the encrypted private key inside the IronCAP
        <TM /> Key will be self-destroyed. The legitimate user needs to purchase
        a new IronCAP
        <TM /> Key and use the Administrator feature to restore the IronCAP
        <TM /> Key.
      </>
    ),
  },
]

const administratorFunctions = [
  {
    label: "Who is the Administrator?",
    id: "whoIsAdmin",
    widget: "text",
    option: (
      <>
        The Administrator of an IronCAP X<TM /> account is usually the person
        that signs up for the account.
      </>
    ),
  },
  {
    label: "What can the Administrator do that a regular user cannot?",
    id: "adminAbilities",
    widget: "text",
    option: (
      <>
        The Administrator can launch the User Manager, My Account, and change
        some admin settings. User Management lets the Administrator add users,
        remove users, reset user password, etc. My Account lets the
        Administrator buy more user licenses. In addition, the Administrator can
        configure admin settings such as the Disaster Recovery settings, etc.
      </>
    ),
  },
  {
    label: "How to enter the special Admin Settings features?",
    id: "adminSettings",
    widget: "text",
    option: (
      <>
        The Administrator can simply launch the IronCAP X<TM /> configuration
        application and click the "Settings" button. IronCAP X<TM />{" "}
        automatically detects if the user is the Administrator. The special
        Admin Settings features tabs (e.g. Password Policy) will only be
        presented if the user is the Administrator of the IronCAP X<TM />{" "}
        account.
      </>
    ),
  },
  {
    label: 'How to enter the "User Management" feature?',
    id: "userManagement",
    widget: "text",
    option: (
      <>
        The Administrator can simply launch the IronCAP X<TM /> configuration
        application and click the "User Management" button. The "User
        Management" button will only be available if the user is detected as
        being the Administrator of the IronCAP X<TM /> account.
      </>
    ),
  },
  {
    label: "How can the Administrator add users?",
    id: "addUsers",
    widget: "text",
    option: (
      <>
        The Administrator can launch the IronCAP X<TM /> configuration
        application and click the "User Management" button. Then click "Add
        User". Note: The IronCAP X<TM /> account must have enough unused
        licenses to permit this operation.
      </>
    ),
  },
  {
    label: "How can the Administrator delete users?",
    id: "deleteUsers",
    widget: "text",
    option: (
      <>
        The Administrator can launch the IronCAP X<TM /> configuration
        application and click the "User Management" button. Select a user to be
        deleted and click "Delete". Note: A free user license will be added back
        after a user has been deleted.
      </>
    ),
  },
  {
    label: "Can a deleted user continue to read old encrypted emails?",
    id: "deletedUserRead",
    widget: "text",
    option: "Yes, but its computer cannot send encrypted emails anymore.",
  },
  {
    label: (
      <>
        Can old encrypted emails be read if IronCAP X<TM /> has been uninstalled
        from the computer?
      </>
    ),
    id: "uninstalledRead1",
    widget: "text",
    option: (
      <>
        No, since the IronCAP X<TM /> private key does not exist anymore.
      </>
    ),
  },
  {
    label: "How to reset the password for user?",
    id: "resetPassword",
    widget: "text",
    option: (
      <>
        The user is not allowed to reset the password. Only the Administrator is
        allowed to do that by launching the IronCAP X<TM /> configuration
        application, clicking the "User Management" button and selecting a user
        whose password needs to be reset. Click "Edit" and "Reset Password".
      </>
    ),
  },
  {
    label: 'How to enter the "My Account" feature?',
    id: "myAccount",
    widget: "text",
    option: (
      <>
        The Administrator can launch the IronCAP X<TM /> configuration
        application and click the "My Account" button. The "User Management"
        button will only be available if the user is detected as being the
        Administrator of the IronCAP X<TM /> account. The My Account feature is
        a web-based operation via the default browser interface. For enhanced
        security, the Administrator will be prompted to enter the password to
        authenticate before the My Account page will be opened. Since a FREE
        personal account only limits to one user, the user of a FREE personal
        account is also the administrator of the account.
      </>
    ),
  },
]

const usingIronCAPX = [
  {
    label: (
      <>
        Is it necessary for both the sender and the recipient(s) to be an
        IronCAP X<TM /> User?
      </>
    ),
    id: "bothUser",
    widget: "text",
    option: (
      <>
        Yes, both the sender and the recipient(s) must be an IronCAP X<TM />{" "}
        user.
      </>
    ),
  },
  {
    label: (
      <>
        What if I try to send encrypted emails to my colleagues who are not
        IronCAP X<TM /> users?
      </>
    ),
    id: "sendColleagues",
    widget: "text",
    option: (
      <>
        IronCAP X<TM /> will automatically detect if the recipient is an IronCAP
        X<TM /> user. If not, it will encrypt the message and insert an
        invitation message asking the recipient to click on the link to sign up
        for a FREE personal account. Once the recipient has signed up for a FREE
        personal account, the user can open the encrypted message and read.
      </>
    ),
  },
  {
    label: (
      <>
        Can I send the same encrypted message to some IronCAP X<TM /> users and
        some who are not?
      </>
    ),
    id: "sendSomeUsers",
    widget: "text",
    option: (
      <>
        Yes, IronCAP X<TM /> will automatically differentiate them. Non-IronCAP
        X<TM /> users will receive the encrypted message plus an invitation
        message asking them to sign up for a FREE personal account.
      </>
    ),
  },
  {
    label:
      "After decrypting a message, will it stay encrypted inside my Inbox?",
    id: "stayEncrypted",
    widget: "text",
    option:
      "Yes, the message continues to stay encrypted inside your Inbox once you have closed it.",
  },
  {
    label: "What if I forward my encrypted email?",
    id: "forward",
    widget: "text",
    option: "You have an option to forward as encrypted or unencrypted.",
  },
  {
    label: "Do I need to select to encrypt for every email I send out?",
    id: "selectEncrypt",
    widget: "text",
    option: (
      <>
        Yes, the default is unencrypted. However, you can change the default in
        the settings of IronCAP X<TM />.
      </>
    ),
  },
  {
    label: "Will the attachment be encrypted too?",
    id: "attachment",
    widget: "text",
    option:
      "The whole email is encrypted so the decryption process will also decrypt the underlying attachment (if any). However, if the attachment itself is an encrypted file, a separate decryption is necessary to open the encrypted file (i.e. double secured).",
  },
  {
    label: (
      <>
        Can old encrypted emails be read if IronCAP X<TM /> has been uninstalled
        from the computer?
      </>
    ),
    id: "uninstalledRead2",
    widget: "text",
    option: (
      <>
        No, since the IronCAP X<TM /> private key does not exist anymore.
      </>
    ),
  },
  {
    label: "Why should I keep a backup of my private key?",
    id: "backup",
    widget: "text",
    option: (
      <>
        For maximum security and privacy, IronCAP X<TM /> does not keep a copy
        of your private key in its server. Therefore, it is necessary for you to
        keep a backup of your private key in case of abnormal circumstances such
        as restoring IronCAP X<TM /> on a new computer after a computer has
        crashed, etc. It is strongly recommended to keep the backup inside a
        storage media residing on a different computer (e.g. an external
        storage, etc.). You will be prompted to specify the backup location
        during the installation of IronCAP X<TM />.
      </>
    ),
  },
]

const billing = [
  {
    label: "How can I upgrade my FREE personal account to a Business account?",
    id: "upgrade",
    widget: "text",
    option: (
      <>
        To upgrade a FREE personal account to a Business account, all you have
        to do is use the "My Account" feature and choose the option "Upgrade to
        a Business account". Enter your company information as well as the
        payment method and click "Pay Now".
      </>
    ),
  },
  {
    label: (
      <>
        What credit cards does IronCAP X<TM /> accept?
      </>
    ),
    id: "creditCards",
    widget: "text",
    option: (
      <>
        IronCAP X<TM /> accepts the following credit cards: Amex, VISA, and
        Mastercard. Your credit card bills will show the charge from 01
        Communique (www.01com.com).
      </>
    ),
  },
  {
    label: "Is there an invoice billing option for corporate accounts?",
    id: "invoice",
    widget: "text",
    option:
      "Invoice billing is only available when a corporation does not allow credit card subscription. Please call our customer service at 1-800-668-2185 for details on setting up a credit account.",
  },
  {
    label: "How would I be charged when adding user licenses?",
    id: "addingUsersCharge",
    widget: "text",
    option:
      "Your credit card will be billed on a prorated basis of the new user licenses up to the next billing cycle on the day when you add user licenses. E.g. if you have an annual account with 5 user licenses and decide to add an extra user license in the middle of a billing cycle you will be charged on your credit card 50% of the new user license. Then at the next billing cycle your credit card will be charged the full annual fee of the total 6 user licenses.",
  },
  {
    label: "How can I remove user licenses?",
    id: "removeLicenses",
    widget: "text",
    option:
      'You can remove user licenses any time by calling our customer service at 1-800-668-2185. Please note that after cancellation, the "defunct" user licenses will be valid until the end of that paid billing cycle.',
  },
  {
    label: "What is the advantage of choosing annual billing?",
    id: "annualBilling",
    widget: "text",
    option: "Annual billing enjoys a 17% discount.",
  },
  {
    label:
      "Can I change my service plan from monthly billing to annual billing?",
    id: "monthlyToAnnual",
    widget: "text",
    option: (
      <>
        No. You cannot change the annual billing to monthly billing yourself.
        Please call our customer service at 1-800-668-2185 to discuss about any
        exceptional situations.
      </>
    ),
  },
  {
    label:
      "Can I change my service plan from annual billing to monthly billing?",
    id: "annualToMonthly",
    widget: "text",
    option:
      "Yes, you can change it any time. However, you need to call customer service at 1-800-668-2185 to facilitate that.",
  },
  {
    label: (
      <>
        Can I cancel my IronCAP X<TM /> account?
      </>
    ),
    id: "cancel",
    widget: "text",
    option: (
      <>
        Yes, you can cancel your IronCAP X<TM /> account any time. Please call
        our customer service at 1-800-668-2185 to facilitate that. Please note
        that after cancellation the user licenses will be valid until the end of
        that paid billing cycle.
      </>
    ),
  },
]

const specialSituations = [
  {
    label: "I have changed my email address.",
    id: "changedEmail",
    widget: "text",
    option: (
      <>
        You will need to uninstall IronCAP X<TM /> and re-install with the new
        email address. Please note that encrypted emails under the old email
        address can no longer be decrypted.
      </>
    ),
  },
  {
    label: (
      <>
        I am the IT of a corporation and have tried IronCAP X<TM /> using the
        FREE account, how can I convert it to the Business account?
      </>
    ),
    id: "convertBusiness",
    widget: "text",
    option: (
      <>
        If you decide to upgrade to the Business Account, all you have to do is
        to use the "My Account" feature and choose the option "Upgrade to a
        Business account". Enter your full company information, as well as the
        payment method and click "Pay Now".
      </>
    ),
  },
  {
    label: (
      <>
        I have tried IronCAP X<TM /> using the FREE account. How do I have my IT
        subscribe to it under a Business account of my company?
      </>
    ),
    id: "ITsubscribe",
    widget: "text",
    option: (
      <>
        Assuming you are not the IT of your company and the email address of
        your IT is different from yours, your IT has already created a Business
        account using its email address and they will be the Administrator.
        During user creation, your IT will need to enter the email address of
        the FREE account. When you or the FREE user runs the installation on
        his/her computer, IronCAP X<TM /> is smart enough to recognize that the
        installation is on top of an existing FREE IronCAP X<TM /> account. It
        will then tie the billing onto this Business account. The new private
        key will be installed on the computer while keeping the old private key
        so that the old encrypted emails can still be opened. The new public key
        (signed by the Admin) will be posted onto the IronCAP X<TM /> server,
        replacing the old one associated with the FREE account.
      </>
    ),
  },
  {
    label: "A staff is taking a temporary leave. Can I put the user on-hold?",
    id: "onHold",
    widget: "text",
    option: (
      <>
        No. There is no such option. If you have deleted the user, the private
        key of that user will be revoked and it cannot be recovered.
      </>
    ),
  },
]

const categories = [
  { label: "General Questions", id: "generalQuestions" },
  { label: "Free vs Business Account", id: "freeVsBusiness" },
  { label: "Security", id: "security" },
  { label: "Administrator Functions", id: "administratorFunctions" },
  {
    label: (
      <>
        Using IronCAP X<TM />
      </>
    ),
    id: "usingIronCAPX",
  },
  { label: "Billing", id: "billing" },
  { label: "Special Situations", id: "specialSituations" },
]

const fileCollection = {
  generalQuestions,
  freeVsBusiness,
  security,
  administratorFunctions,
  usingIronCAPX,
  billing,
  specialSituations,
}

class IronCAPXFAQ extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeCategory: "generalQuestions",
    }
  }

  setActiveCategory = e => {
    this.setState({
      activeCategory: e.target.id,
    })
  }
  render() {
    return (
      <Layout>
        <HeaderBanner
          title={
            <>
              IronCAP X<TM /> FAQs
            </>
          }
        />
        <div className={styles.pageContainer}>
          <Breadcrumb
            firstLink="/support"
            firstLinkTitle="Support"
            secondLink="/ironcap-x/faq"
            secondLinkTitle={
              <>
                IronCAP X<TM /> FAQ
              </>
            }
          />
          <div className={styles.FAQContainer}>
            <FAQCategoriesContainer
              categories={categories}
              setActiveCategory={this.setActiveCategory}
              activeCategory={this.state.activeCategory}
            />
            <FAQListContainer
              activeCategory={fileCollection[this.state.activeCategory]}
            />
          </div>
        </div>
        <CTABanner
          tagline="Step Into The Future"
          CTA="video"
          CTADetail="how video"
        />
      </Layout>
    )
  }
}

export default IronCAPXFAQ
